.gallery_image_content {
  max-height: 200px;
}

.top-right {
  position: absolute;
  left: 60%;
  right: 0;
  background: rgba(215, 204, 219, 0.89);
  z-index: 999;
}

.top-right h5 {
  margin-bottom: 10px !important;
  line-height: 1.1 !important;
  font-weight: 500 !important;
  margin-left: 5%;
}

.member-mobile {
  background: white;
}

.team-ing81-holder {
  text-align: center;
}

.member {
  text-align: center;
  width: 100%;
}

.member img {
  max-width: 70%;
}

.member-mobile p {
  margin: 0;
}
